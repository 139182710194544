import React, { FC } from 'react';

import BenefitsItem from 'components/elements/BenefitsItem';
import Typography from 'components/elements/Typography';
import BaseSection from 'components/helpers/BaseSection';

import { IPropsAdvantagesSection } from './models';

import './AdvantagesSection.scss';

const AdvantagesSection: FC<IPropsAdvantagesSection> = ({ data: [data] }) => {
  const { items, title, text, subTitle, sectionSettings } = data;

  return (
    <BaseSection data={sectionSettings}>
      <div data-testid="AdvantagesSection" className="advantages-section">
        <Typography data={title} customClass="advantages-section__title" />
        {text ? <Typography data={text} customClass="advantages-section__text" /> : null}
        <div className="advantages-section__items-wrapper">
          {subTitle ? (
            <Typography data={subTitle} customClass="advantages-section__subTitle" />
          ) : null}
          <div className="advantages-section__items">
            {items.map((item) => (
              <BenefitsItem key={item.title[0].text} data={[item]} />
            ))}
          </div>
        </div>
      </div>
    </BaseSection>
  );
};

export default AdvantagesSection;
