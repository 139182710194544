import React, { FC } from 'react';
import { Link } from 'gatsby';

import Typography from 'components/elements/Typography';
import DangerouslySetInnerHtml from 'components/helpers/DangerouslySetInnerHtml';
import GatsbyImage from 'components/helpers/GatsbyImage';
import IconCustom from 'components/helpers/IconCustom';

import { IPropsSecondaryNavigationItem } from './models';

import './SecondaryNavigationItem.scss';

const SecondaryNavigationItem: FC<IPropsSecondaryNavigationItem> = ({ data: [data] }) => {
  const { description, image, link } = data;
  const { url, name, queryString } = link[0];
  const path = queryString && queryString !== '' ? `${url}${queryString}` : url;

  return (
    <div data-testid="SecondaryNavigationItem" className="secondary-navigation-item">
      <Link to={path} className="secondary-navigation-item__link" aria-label={name}>
        <div className="secondary-navigation-item__image">
          <GatsbyImage image={image} objectFit="contain" />
        </div>
        <div className="secondary-navigation-item__content">
          <div className="secondary-navigation-item__label">
            <DangerouslySetInnerHtml
              html={name}
              element="span"
              className="secondary-navigation-item__label-text"
            />
            <IconCustom
              icon="chevron-right-bold"
              className="secondary-navigation-item__label-icon"
            />
          </div>
          <Typography data={description} customClass="secondary-navigation-item__description" />
        </div>
      </Link>
    </div>
  );
};

export default SecondaryNavigationItem;
