import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import AdvantagesSection from 'components/sections/AdvantagesSection';
import HeroBanner from 'components/sections/HeroBanner';
import HowItWorks from 'components/sections/HowItWorks';
import PopularArticles from 'components/sections/PopularArticles';
import PopularProducts from 'components/sections/PopularProducts';
import SecondaryNavigation from 'components/sections/SecondaryNavigation';

import { IPropsHomePage } from './models';

import './HomePage.scss';

const HomePage: FC<IPropsHomePage> = ({
  data: {
    pageData,
    siteSettings,
    popularArticlesByLink,
    popularArticlesByTag,
    popularProductsByLink,
    popularProductsByTag,
  },
  pageContext,
}) => {
  const {
    seo,
    urls,
    createDate,
    updateDate,
    popularProducts,
    heroBanner,
    popularArticles,
    howItWorks,
    secondaryNavigation,
    advantagesSection,
    showBreadcrumbsDesktop,
    showBreadcrumbsMobile,
  } = pageData;
  const { breadcrumbs, lang, url } = pageContext;

  return (
    <Layout
      data-testid="HomePage"
      seo={seo}
      lang={lang}
      urls={urls}
      pagePathname={url}
      createDate={createDate}
      updateDate={updateDate}
      className="home-page"
    >
      <HeroBanner
        data={heroBanner}
        breadcrumbs={breadcrumbs}
        isShowBreadcrumbsMobile={showBreadcrumbsMobile}
        isShowBreadcrumbsDesktop={showBreadcrumbsDesktop}
      />
      <SecondaryNavigation data={secondaryNavigation} />
      {advantagesSection?.length ? <AdvantagesSection data={advantagesSection} /> : null}
      {popularProducts?.length && (popularProductsByLink?.nodes || popularProductsByTag?.nodes) ? (
        <PopularProducts
          popularProductsByLink={popularProductsByLink?.nodes}
          popularProductsByTag={popularProductsByTag?.nodes}
          data={popularProducts}
          globalData={{
            ariaLabelNext: siteSettings.ariaLabelNext,
            ariaLabelPrev: siteSettings.ariaLabelPrev,
            dir: siteSettings.dir,
          }}
        />
      ) : null}
      {howItWorks?.length ? <HowItWorks data={howItWorks} /> : null}
      {popularArticles?.length && (popularArticlesByLink?.nodes || popularArticlesByTag?.nodes) ? (
        <PopularArticles
          popularArticlesByLink={popularArticlesByLink?.nodes}
          popularArticlesByTag={popularArticlesByTag?.nodes}
          data={popularArticles}
          globalData={{
            ariaLabelNext: siteSettings.ariaLabelNext,
            ariaLabelPrev: siteSettings.ariaLabelPrev,
            dir: siteSettings.dir,
          }}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query (
    $url: String!
    $lang: String
    $selectedArticlesLinks: [String]
    $selectedArticlesTag: Int
    $selectedProductsLinks: [String]
    $selectedProductsTag: Int
  ) {
    pageData: homePage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentHomePage
    }
    siteSettings: siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    popularArticlesByLink: allArticlePage(
      filter: { url: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCard
      }
    }
    popularArticlesByTag: allArticlePage(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCard
      }
    }
    popularProductsByLink: allProductPage(
      filter: { url: { in: $selectedProductsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    popularProductsByTag: allProductPage(
      filter: { tags: { elemMatch: { id: { eq: $selectedProductsTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
  }
`;

export default HomePage;
