import React, { FC } from 'react';

import Button from 'components/elements/Button';
import SecondaryNavigationItem from 'components/elements/SecondaryNavigationItem';
import BaseSection from 'components/helpers/BaseSection';

import { IPropsSecondaryNavigation } from './models';

import './SecondaryNavigation.scss';

const SecondaryNavigation: FC<IPropsSecondaryNavigation> = ({ data: [data] }) => {
  const { items, ctaButton, sectionSettings } = data;

  return (
    <BaseSection data={sectionSettings}>
      <div data-testid="SecondaryNavigation" className="secondary-navigation">
        <div className="secondary-navigation__items">
          {items.map((item) => (
            <SecondaryNavigationItem key={item.link[0].name} data={[item]} />
          ))}
        </div>
        <div className="secondary-navigation__cta">
          <Button data={ctaButton} />
        </div>
      </div>
    </BaseSection>
  );
};

export default SecondaryNavigation;
